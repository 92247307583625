import React, { useEffect, useState } from "react";
import useWeb3Modal from "../hooks/useWeb3Modal";

import {
Avatar,
Box,
Button,
Collapse,
Flex,
HStack,
Icon,
IconButton,
Image,
Link,
Menu,
// MenuButton,
MenuDivider,
// MenuGroup,
MenuItem,
// MenuItemOption,
MenuList,
// MenuOptionGroup,
Popover,
PopoverContent,
PopoverTrigger,
Stack,
Text,
// useBreakpointValue,
useColorModeValue,
useDisclosure,
} from "@chakra-ui/react";

import {
HamburgerIcon,
// AddIcon,
CloseIcon,
ChevronDownIcon,
ChevronRightIcon,
} from "@chakra-ui/icons";
// import { FcGoogle } from "react-icons/fc";
// import { FaEthereum } from "react-icons/fa";

// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w-f.png";
// import background from "./images/mg-bckg-06c-1280w-f.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";



function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
const [account, setAccount] = useState("");
const [rendered, setRendered] = useState("");

useEffect(() => {
async function fetchAccount() {
try {
if (!provider) {
return;
}

// Load the user's accounts.
const accounts = await provider.listAccounts();
setAccount(accounts[0]);

// Resolve the ENS name for the first account.
const name = await provider.lookupAddress(accounts[0]);

// Render either the ENS name or the shortened account address.
if (name) {
setRendered(name);
} else {
setRendered(account.substring(0, 6) + "..." + account.substring(36));
}
} catch (err) {
setAccount("");
setRendered("");
console.error(err);
}
}
fetchAccount();
}, [account, provider, setAccount, setRendered]);

return (
<HStack
mt="10px"
align="flex"
flex={{ base: 1, md: 0 }}
justify={"flex-end"}
direction={"row"}
spacing={6}
>
<Button
bg={"blue.500"}
display={{ base: "none", md: "inline-flex" }}
w={"full"}
size={"md"}
fontSize={"md"}
fontWeight={600}
color={"yellow.300"}
// p={"24pt"}
ml={"4"}
mr={"2"}
onClick={() => {
if (!provider) {
loadWeb3Modal();
} else {
logoutOfWeb3Modal();
}
}}
>
{rendered === "" && "Connect Web3 Account"}
{rendered !== "" && rendered}
</Button>
</HStack>
);
}

function Header1() {
const { isOpen, onToggle } = useDisclosure();
const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();



return (
<div style={{ backgroundImage: `url(${background})` }}>
<Box>
<Flex
// bg={useColorModeValue("gray.900", "gray.800")}
color={useColorModeValue("gray.900", "gray.200")}
minH={"60px"}
py={{ base: 2 }}
px={{ base: 4 }}
borderBottom={1}
borderStyle={"solid"}
borderColor={useColorModeValue("gray.200", "gray.900")}
align={"center"}
>
<Flex
flex={{ base: 1, md: "auto" }}
ml={{ base: -2 }}
display={{ base: "flex", md: "none" }}
>
<IconButton
onClick={onToggle}
icon={
isOpen ? (
<CloseIcon w={3} h={3} />
) : (
<HamburgerIcon w={5} h={5} />
)
}
variant={"ghost"}
aria-label={"Toggle Navigation"}
/>
</Flex>

<Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
{/* <Avatar
ml={"2"}
size={"xl"}
src={
// " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
// " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
// " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
// " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
// " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
" https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
// " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "

}
/> */}
<Avatar
ml={"2"}
size={"2xl"}
backgroundColor={"blue.600"}
src={
// " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
// " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
// " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
// " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
// " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
// " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
// " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
// " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
// " https://bafybeiakbeu5a6g4jhrvurbamc75q6d2yoo23ihjguk7va4bdnvdfa5bhe.ipfs.infura-ipfs.io/ "
// " https://bafybeig6ya4h6ozshcm5tcad5i2aklumdhokzobs4l3xjq7q4gwvg5idcu.ipfs.infura-ipfs.io/ "
// " https://bafybeicrnln2gm2a5ivbeeepdupdeg33gzzboxw3smo3ssgtx2xoxrrfoe.ipfs.infura-ipfs.io/ "
// " https://bafybeicjsmb535kxrkwdsfqm7oruiionzf2yvhwl2fxhwvf2j756zkfwcq.ipfs.infura-ipfs.io/ "
// " https://bafybeidqzoh3flsxoyy264653ctvvero6k52eyrlp6v2qhpk57dacfktb4.ipfs.infura-ipfs.io/ "
// " https://bafybeifqq4uwoiw2dc5cqimpic3wjp5zhm6uyyuxmspuvvh4kvu74dlyta.ipfs.infura-ipfs.io/ "
// " https://bafybeia7bselvncsp4oothrkbnalkvhibn5kxzlln2mfjyqmzdjuwjzg4m.ipfs.infura-ipfs.io/ "
// " https://bafybeiha6cbbec45tpuydl47yutknsmg344nfxy5vgquuluhw7xoiwoj3a.ipfs.infura-ipfs.io/ "
// " https://bafybeiafc3dldgxrmd3uoyredjvj6ok46iop5lqq6psb5cacii6ehaauh4.ipfs.infura-ipfs.io/ "
// " https://bafybeiebd6bzyfhngokxnivjhjrt5aon3u44633ciq6lm7tocxlpthy65m.ipfs.infura-ipfs.io/ "

// " https://bafybeic7pou47tnpfxin2fumjnvur7ae3asw7u6cxwz5ehkapoxl2lv7d4.ipfs.infura-ipfs.io/ "
// " https://bafybeibxy67mzalhgohwz2vcpqcktaq47ap2onoqh2saykjcsijoln3vmy.ipfs.infura-ipfs.io/ "
// " https://bafybeibdkqo35uc322jlko3zyjojv4xlpyxpygjecgr6urux5th6cnbxju.ipfs.infura-ipfs.io/ "
// " https://bafybeib5jf3ros7klylxleqj4q72ka5rgykflabdsqo36a3obx2wjixpp4.ipfs.infura-ipfs.io/ "
// " https://bafybeiaafj4jnp66uouhgueeehy4l47v75u6lz4wfquzib7hfpwezpayhi.ipfs.infura-ipfs.io/ "
// " https://bafybeidkmtyetadwa5cfzqdbm43shfwrpgkqrbtktaw6qguu4zqisuhgu4.ipfs.infura-ipfs.io/ "
// " https://bafybeia2eitu2ss4fzjn42trtu3z6r6dhhwgvkhzfde3qruobiremfbwpa.ipfs.infura-ipfs.io/ "
// " https://bafybeib4so2n7gggopq476kjgfx2o7env63wqzhbw7tr3npj2hq7galol4.ipfs.infura-ipfs.io/ "
// " https://bafybeifiaoxjhfzqyfk3stxb6qfzip5dfqvtap4rrpd5o7ur5y2v33n6ei.ipfs.infura-ipfs.io/ "
// " https://bafybeicyij2rnun7jiphodqyutvycm64hhs2d3zm4m5evuqsca5zujboge.ipfs.infura-ipfs.io/ "
// " https://bafybeibphb53hcep3xcnkfuy6gakgutfpfhzfo5gs57sjz2thu4mca4nyi.ipfs.infura-ipfs.io/ "
// " https://bafybeihtqc7phwfkddr2kv72wnq3ypby53vjyccuvdwfiuep6oo7pfgoqe.ipfs.infura-ipfs.io/ "
// " https://bafybeic7lojs3dpwliwgiat6ctmip3zrw3lafcike4k3ubm4zrzufgwzgi.ipfs.infura-ipfs.io/ "
// " https://bafybeiefkmoai22wpsxi47ix64hvx2waxhxfk5jxttamoxy3znfvwjkkgi.ipfs.infura-ipfs.io/ "
// " https://bafybeih3nmzbvthww4ecgxqgeardtjnem77d4z4q4tc7qgcblzc7dvvppe.ipfs.infura-ipfs.io/ "
// " https://bafybeif36qk2bgwdkqenyr4di3pxg4htvpr44pgqwgga6owki5ks6bebje.ipfs.infura-ipfs.io/ "
// " https://bafybeiebnpiqtqjfb3c7fx5fr45em7htjmuay23egmfilhkvckty7d7mhy.ipfs.infura-ipfs.io/ "
// " https://bafybeifd3o6suhj6eou5bteyjrgvicdsm3pgzli2tddxtuixczur4vaotu.ipfs.infura-ipfs.io/ "
" https://bafybeic3dssb32jkkabcqksydk7ocqejhd7ch2pnl4cvocl44xcpbpca5a.ipfs.infura-ipfs.io/ "


}
/>
<Box flexShrink={0}>
<Image
ml="15px"
mt="20px"
// borderRadius="lg"
maxW="400px"
width={{ lg: 400, md: 100, sm: 160, base: 200}}
src=
// " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io "
// " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
// " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
// " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/ "
// " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/ "
// " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
// " https://bafybeia7bselvncsp4oothrkbnalkvhibn5kxzlln2mfjyqmzdjuwjzg4m.ipfs.infura-ipfs.io/ "
// " https://bafybeiha6cbbec45tpuydl47yutknsmg344nfxy5vgquuluhw7xoiwoj3a.ipfs.infura-ipfs.io/ "
// " https://bafybeiafc3dldgxrmd3uoyredjvj6ok46iop5lqq6psb5cacii6ehaauh4.ipfs.infura-ipfs.io/ "
// " https://bafybeiebd6bzyfhngokxnivjhjrt5aon3u44633ciq6lm7tocxlpthy65m.ipfs.infura-ipfs.io/ "
// " https://bafkreidpfk7oakj44bci5tmutdryhfaalgo3oxn4q3xlbxbss77b26tdga.ipfs.infura-ipfs.io "
// " https://bafkreihxnhqsoit5kk5fu5ozf5axpqeo6hegyubu7lzoy7a7mgbwgkuccm.ipfs.infura-ipfs.io "
// " https://bafkreiceet54xtdkvlgzkvhavobgkdcootvzg3ds5nz3tbktug55ai2wle.ipfs.infura-ipfs.io "
" https://bafybeidvu2fvi7n7sa2fdfcbvsutsmdqmwe2mfmijnmkh7j4phuswuzzce.ipfs.infura-ipfs.io/ "



alt=" MetaCollab "
/>
</Box>
</Flex>
<Stack
flex={{ base: 1, md: 0 }}
justify={"flex-end"}
direction={"row"}
spacing={2}
>

<br />

{/* <Menu fontFamily="Exo 2, san-serif">
<MenuButton as={Button} 
size={"sm"}
colorScheme="blue" 
variant="solid">
Fund a Gig
</MenuButton>
<MenuList color="gray.900">
<MenuGroup title=" Profile">
<MenuItem> Extend a Handshake </MenuItem>
<MenuItem> Create a New Ask </MenuItem>
</MenuGroup>
<MenuDivider />
<MenuGroup title=" Help">
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
User Guide 
</Link>
</MenuItem>
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
Terms and Conditions 
</Link>
</MenuItem>
</MenuGroup>
</MenuList>
</Menu> */}




{/* <Menu fontFamily="Exo 2, san-serif">
<MenuButton as={Button} 
size={"sm"}
colorScheme="blue" 
variant="solid">
Do a Gig
</MenuButton>
<MenuList color="gray.900">
<MenuGroup title=" Profile">
<MenuItem> Search By DAO </MenuItem>
<MenuItem> Search By Skills </MenuItem>
</MenuGroup>
<MenuDivider />
<MenuGroup title=" Help">
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
User Guide 
</Link>
</MenuItem>
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
Terms and Conditions 
</Link>
</MenuItem>
</MenuGroup>
</MenuList>
</Menu>
 */}

{/* 
<br />
<Menu closeOnSelect={false}>
<MenuButton as={Button} 
size={"sm"}
mr={4}
// colorScheme="green" 
variant="solid" 
leftIcon={<AddIcon />}
>
Gig Actions
</MenuButton>
<MenuList minWidth="240px" bgColor="gray.300" color="blue" fontStyle="Exo 2, san-serif">
<MenuOptionGroup defaultValue="sendH" fontStyle="Exo 2, san-serif" color="gray.800" fontSize="lg" title=" Gig Actions" type="checkbox">
<MenuOptionGroup title=" Blockchain Transactions" type="checkbox">
<MenuItemOption value="sendH"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Send a Handshake </Link></MenuItemOption>
<MenuItemOption value="signH"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Sign a Handshake </Link></MenuItemOption>
<MenuItemOption value="createG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Create a Gig </Link></MenuItemOption>
<MenuItemOption value="signG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Sign a Gig </Link></MenuItemOption>
<MenuItemOption value="startG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Start a Gig </Link></MenuItemOption>
<MenuItemOption value="cancelG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Cancel a Gig </Link></MenuItemOption>
<MenuItemOption value="completeG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Complete a Gig </Link></MenuItemOption>
<MenuItemOption value="addR"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Add a Resolver </Link></MenuItemOption>
<MenuItemOption value="confR"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Confirm a Resolver </Link></MenuItemOption>
<MenuItemOption value="lockG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Lock a Gig </Link></MenuItemOption>
<MenuItemOption value="add3P"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Add a Third-Party Beneficiary </Link></MenuItemOption>
<MenuItemOption value="resolveG"> <Link target="_blank" href="https://buildagig.metacollab.freeweb3.com/" >Resolve a Gig </Link></MenuItemOption>
</MenuOptionGroup>
</MenuOptionGroup>
<MenuDivider />

<MenuOptionGroup defaultValue="inCaState" color="gray.800" fontSize="lg" title=" Jurisdiction" type="radio">
<MenuItemOption value="inCaState"> In California </MenuItemOption>
<MenuItemOption value="outofCaState"> In USA, not California </MenuItemOption>
<MenuItemOption value="outofUSA"> Outside USA </MenuItemOption>
</MenuOptionGroup>
</MenuList>
</Menu> */}



<WalletButton
provider={provider}
loadWeb3Modal={loadWeb3Modal}
logoutOfWeb3Modal={logoutOfWeb3Modal}
/>

<Menu>
{/* <MenuButton
as={Button}
rounded={"full"}
variant={"link"}
cursor={"pointer"}
minW={0}
>
<Avatar
size={"lg"}
src={
// " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
// " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
// " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
// " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
// " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
// " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
// " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
// " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
// " https://bafybeianeok6ic7irwtxgrblulbairprwpxo2bk4brwftvqcywcwb5k6ou.ipfs.infura-ipfs.io/ "
// " https://bafybeidockxkzred5eiymuwbgtxueln4d42sy7si2oqtjhyvo2gwpfkeim.ipfs.infura-ipfs.io/ "
// " https://bafybeiepkjprcey6awxfvhcsls6s5ghqkaq5zmfziu4s7mxvnqcughpzvm.ipfs.infura-ipfs.io/ "
// " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
// " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
// " https://bafybeia7bselvncsp4oothrkbnalkvhibn5kxzlln2mfjyqmzdjuwjzg4m.ipfs.infura-ipfs.io/ "
" https://bafybeiha6cbbec45tpuydl47yutknsmg344nfxy5vgquuluhw7xoiwoj3a.ipfs.infura-ipfs.io/ "
// " https://bafybeiafc3dldgxrmd3uoyredjvj6ok46iop5lqq6psb5cacii6ehaauh4.ipfs.infura-ipfs.io/ "
// " https://bafybeiebd6bzyfhngokxnivjhjrt5aon3u44633ciq6lm7tocxlpthy65m.ipfs.infura-ipfs.io/ "

}
/>
</MenuButton> */}


{/* <MenuButton
as={Button}
rounded={"full"}
variant={"link"}
cursor={"pointer"}
minW={0}
>
<Avatar
size={"lg"}
src={
// " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
// " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
// " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
// " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
// " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
// " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
// " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
// " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
// " https://bafybeianeok6ic7irwtxgrblulbairprwpxo2bk4brwftvqcywcwb5k6ou.ipfs.infura-ipfs.io/ "
// " https://bafybeidockxkzred5eiymuwbgtxueln4d42sy7si2oqtjhyvo2gwpfkeim.ipfs.infura-ipfs.io/ "
" https://bafybeiepkjprcey6awxfvhcsls6s5ghqkaq5zmfziu4s7mxvnqcughpzvm.ipfs.infura-ipfs.io/ "



}
/>
</MenuButton> */}

<MenuList color="gray.900">
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
User Guide 
</Link>
</MenuItem>
<MenuDivider />
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
Terms and Conditions 
</Link>
</MenuItem>
<MenuItem> 
<Link     
target="_blank"
href="https://userguide.metacollab.freeweb3.com/" >
Privacy Policy 
</Link>
</MenuItem>
</MenuList>
</Menu>
</Stack>
</Flex>


<Box mt={{ base: 4, md: 2 }} ml={{ md: 6 }}>

<HStack
mt="10px"
align="flex"
flex={{ base: 1, md: 0 }}
justify={"flex-end"}
direction={"row"}
spacing={6}
>
<DesktopNav />
</HStack>
</Box>
</Box>

<Collapse in={isOpen} animateOpacity>
<MobileNav />
</Collapse>
</div>
);
}
const DesktopNav = () => {
// const linkColor = useColorModeValue("gray.600", "gray.200");
const linkHoverColor = useColorModeValue("blue.200", "red.200");
const popoverContentBgColor = useColorModeValue("gray.500", "gray.900");
return (
<Stack direction={"row"} spacing={6}>
{NAV_ITEMS.map((navItem) => (
<Box mb={"24pt"} key={navItem.label}>
<Popover trigger={"hover"} placement={"bottom-start"}>
<PopoverTrigger>
<Link
target="_blank"
p={2}
href={navItem.href ?? " # "}
fontSize={"lg"}
fontWeight={700}
color={"gray.200"}
_hover={{
textDecoration: "none",
color: linkHoverColor,
}}
>
{navItem.label}
</Link>
</PopoverTrigger>
{navItem.children && (
<PopoverContent
border={0}
boxShadow={"xl"}
bg={popoverContentBgColor}
p={4}
rounded={"xl"}
minW={"sm"}
>
<Stack>
{navItem.children.map((child) => (
<DesktopSubNav key={child.label} {...child} />
))}
</Stack>
</PopoverContent>
)}
</Popover>
</Box>
))}
</Stack>
);
};
const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
return (
<Link
target="_blank"
href={href}
role={" group "}
display={"block"}
p={2}
rounded={"md"}
_hover={{ bg: useColorModeValue("pink.50", "gray.500") }}
>
<Stack direction={"row"} align={"center"}>
<Box>
<Text
transition={"all .3s ease"}
_groupHover={{ color: "pink.400" }}
fontWeight={500}
>
{label}
</Text>
<Text fontSize={"sm"}>{subLabel}</Text>
</Box>
<Flex
transition={"all .3s ease"}
transform={"translateX(-10px)"}
opacity={0}
_groupHover={{ opacity: "100%", transform: "translateX(0)" }}
justify={"flex-end"}
align={"center"}
flex={1}
>
<Icon as={ChevronRightIcon} w={5} h={5} color="pink.400" />
</Flex>
</Stack>
</Link>
);
};
const MobileNav = () => {
return (
<Stack
bg={useColorModeValue("gray.200", "gray.800")}
p={4}
display={{ md: "none" }}
>
{NAV_ITEMS.map((navItem) => (
<MobileNavItem key={navItem.label} {...navItem} />
))}
</Stack>
);
};
const MobileNavItem = ({ label, children, href }: NavItem) => {
const { isOpen, onToggle } = useDisclosure();
return (
<Stack spacing={4} onClick={children && onToggle}>
<Flex
py={2}
as={Link}
href={href ?? " # "}
justify={"space-between"}
align={"center"}
_hover={{
textDecoration: "none",
}}
>
<Text
fontWeight={600}
color={useColorModeValue("gray.600", "gray.200")}
>
{label}
</Text>
{children && (
<Icon
as={ChevronDownIcon}
transition={"all .25s ease-in-out"}
transform={isOpen ? "rotate(180deg)" : ""}
w={6}
h={6}
/>
)}
</Flex>
<Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
<Stack
mt={2}
pl={4}
borderLeft={1}
borderStyle={"solid"}
borderColor={useColorModeValue("gray.200", "gray.700")}
align={"start"}
>
{children &&
children.map((child) => (
<Link 
target="_blank"
key={child.label} 
py={2} 
href={child.href}>
{child.label}
</Link>
))}
</Stack>
</Collapse>
</Stack>
);
};
interface NavItem {
label: string;
subLabel?: string;
children?: Array<NavItem>;
href?: string;
}
const NAV_ITEMS: Array<NavItem> = [
{
label: "The Club ",
children: [
{
label: "LexAutomatico The Club ",
subLabel: "The Law School Student Association Consortium ",
href: " https://lexautomatico.com/ ",

},
{
  label: "The Culture - Webelexa  ",
  subLabel: "A Newsletter Support Site",
  href: " https://webelexa.com/ ",
  
  },
{
label: "The Creds  ",
subLabel: "Digital Artifacts to Prove your Humanity",
href: " https://tokens.freeweb3.com/ ",

},
],
},
{
label: "The Cart ",
children: [
{
label: "Web3 Legal Tools ",
subLabel: "Web3 Courses MCLE and CLE",
href: " https://web3legaltools.com ",

},
{
label: "California MCLE - Intro to Web3 ",
subLabel: "How Web3 May Affect the Legal Profession",
href: " https://web3legaltools.com/product/california-mcle-intro-to-web3-how-web3-may-affect-the-legal-industry-1-25-credits/ ",
},
],
},
{
label: "LexComms ",
children: [
{
label: "LexNewsPlus Content Site ",
subLabel: "Choose your own Web3 adventure",
href: " https://lexnewsplus.com ",


},
{
label: "LexComms Adminiration Site ",
subLabel: "Up-and-coming Developers choose Web3",
href: " https://lexcomms.freeweb3.com/ ",

},
],
},
{
label: "The Courses ",
children: [
{
label: "Classes and Courses Login ",
subLabel: "Learning Management System",
href: " https://web3lexa.com/edu ",

},
{
label: "The Development Stack & Craft ",
subLabel: "SmartLaw Web3Skills SuperSite",
href: " https://smartlaw.freeweb3.com/ ",

},
],
},
];
export default Header1;
